import React from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import { writeSettings } from "@/app/actions.mjs";
import {
  PageContainer,
  Panel,
  SettingsContent,
  SettingsDescription,
  SettingsLabel,
  SettingsList,
  SettingsListItem,
} from "@/hub-settings/Common.style.jsx";
import { ToggleSwitch } from "@/hub-settings/ToggleSwitch.jsx";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function UnknownSettings() {
  const { t } = useTranslation();
  const {
    settings: {
      unknown: { toggle1, toggle2 },
    },
  } = useSnapshot(readState);

  const gameSettings = [
    {
      key: "toggle1",
      label: t("unknown:toggle1", "Toggle 1"),
      description: t("unknown:toggle1description", "Toggle 1 Description"),
      input: (
        <ToggleSwitch
          value={toggle1}
          onChange={({ value }) => writeSettings(["unknown", "toggle1"], value)}
        />
      ),
    },
    {
      key: "toggle2",
      label: t("unknown:toggle2", "Toggle 2"),
      description: t("unknown:toggle2description", "Toggle 2 Description"),
      input: (
        <ToggleSwitch
          value={toggle2}
          onChange={({ value }) => writeSettings(["unknown", "toggle2"], value)}
        />
      ),
    },
  ];

  return (
    <PageContainer>
      <Panel>
        <h5 className="type-article-headline">
          {t("common:settings.gameSettings", "Game Settings")}
        </h5>
        <SettingsList>
          {gameSettings.map(({ key, label, description, input }) => (
            <SettingsListItem key={key}>
              <SettingsContent>
                <SettingsLabel className="type-form--button">
                  {label}
                </SettingsLabel>
                {description && (
                  <SettingsDescription className="type-caption">
                    {description}
                  </SettingsDescription>
                )}
              </SettingsContent>
              {input}
            </SettingsListItem>
          ))}
        </SettingsList>
      </Panel>
    </PageContainer>
  );
}
